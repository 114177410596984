import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = ({uri, location}) => (
    <Layout page="privacy">
        <SEO title="Privacy Policy" />
        <div className="container mb-2">
            <div style={{ mposition:"relative", color:"#666", marginBottom: `1.45rem` }}>
                <h2 className="mt-4 mb-4 text-center" style={{color:"#333"}}>Terms and Conditions</h2>

            <p>This Web Site (and any other site accessed through this site) and the services provided therein are only available to users seeking employment ("Jobseekers") or users seeking employees or advertisers ("Employers") and are subject to the Terms and Conditions which are set out below. If you do not accept the Terms and Conditions, please do not use this Web Site. Upon your acceptance of the Terms and Conditions, they are binding upon you. Jobsmigas ("the Company") may revise the Terms and Conditions at anytime by updating this section. You are advised to visit this page periodically to review the Terms and Conditions. Your access the Company's Web Site and its services will be terminated upon your notice to the Company that any change is unacceptable otherwise your continued use will constitute your acceptance of all changes and they will be binding upon you.</p>
	
            <ol>
            <li>
                <strong>Acceptable Web Site Uses</strong> <br/>
                <ul>
                    <li>Specific Uses - Jobseekers <br/>

                    <p>Jobseekers agree that they will only use the Company's Web Sites (and any other sites accessed through them) for lawful purposes and to seek employment. Jobseekers may send their resume directly to any Employer who advertises on or through the Company Web Sites. Alternatively the Jobseeker may send his resume to the Company through the Company's Web Sites in reply to any job advertisement. In such event the Jobseeker's resume will also remain active on the Company's Web Sites for a predetermined period and, unless the Jobseeker chooses to delete it, it will thereafter be stored in the Company's Database at the Jobseeker's own risk. The Company also reserves the right to edit any Jobseeker's resume as it sees fit. Jobseekers acknowledge and agree to the Company's practice of charging Employers a fee for access to the Company's web sites, Database and to the Jobseeker's resumes and details. The Jobseekers also recognizes that he has no claims to any such fees received by the Company. The Company will not release the Jobseeker's personal data to any potential employer without his permission but the Company will release the Jobseeker's brief resume (without his name and address) to third parties through the use of the Company's search engine or any other means. However Jobseekers do agree that the Company and its associated companies may use the Jobseeker's personal data for marketing purposes. Further the Company will not be responsible or held liable in any way if any Employer, whether in Indonesia or elsewhere, should use the Jobseeker's personal data for any other use other than for obtaining potential employees. Jobseekers accept that personal data given to Employers or other users are given entirely at their own risk.</p>
                    </li>

                    <li> <strong>Specific Uses - Employers</strong><br/>

                    <p>Upon payment of the Service Fee to the Company or upon acceptance of any free trial promotion offer, the Employer will be entitled to use the Company's Web Sites to advertise job vacancies and/or create their own web page on the Company's Web Site for a predetermined period following agreement of the predetermined service. The only exception is a free trial promotional offer. Employers will also be entitled to access the Company's Jobseekers Database but agree that all information obtained will only be used for the purpose of obtaining potential employees. Employers will be solely responsible for the contents and materials contained in their advertisement and/or web page. The Company reserves the right to edit any advertisement or web page as it sees fit. The Company reserves the right to change the Service Fee or institute new charges or fees, as it deems appropriate. In the event that any Employer should fail to pay the Service Fee or any other fees or changes due to the Company, the Company reserves the right to suspend or terminate the Employer's web page or advertisement, without prejudice to all other rights and remedies.</p></li>

                    <li> <strong>Prohibited Uses - Jobseekers and Employers</strong> <br />

                        <p>Jobseekers and Employers agree not to use any of the Company's Web Sites for any of the following purposes which are expressly prohibited :-</p>
                        
                        <ul>
                            <li>Jobseekers shall not post any non-resume information and/or incomplete, false or inaccurate resume information which is not their own accurate resume.</li>
                            
                            <li>Jobseekers shall not respond to any employment opportunity for any reason other than to apply for the job advertised and Employers shall not respond to any Jobseeker other than in connection with their application for a job. Communications soliciting business for any reason is prohibited.</li>
                            <li>All users shall not print, download, duplicate or otherwise copy or use any personally identifiable information about other users. All unsolicited communications of any type to users is strictly prohibited.</li>

                            <li>All users shall not delete or revise any material posted by any other person or entity.</li>
                            <li>All users are prohibited from violating or attempting to violate the security of the Company's Web Sites (or any other sites accessed through the Company's Web Sites) including, without limitation, accessing data not intended for them or logging into a server or account which they are not authorised to access, attempting to probe, scan or test the vulnerability of a system or network or attempting to breach security or authentication measures without proper authorization, attempting to interfere with service to any user, host or network or sending unsolicited e-mail, including promotions and/or advertising of products or services, Violations of system or network security may result in civil or criminal liability.</li>
                            <li>All users will not use the Company's Web Sites in order to transmit, distribute or store material in violation of any applicable law or regulation, or in any manner that will infringe the copyright, trademark, trade secrets or other intellectual property rights of others or violate the privacy or publicity or other personal rights of others, or that is libelous, obscene, threatening, abusive or hateful.</li>
                        </ul>
                    </li>
                    </ul>
                </li>

                <li> <strong>Copyright, Trademarks etc.</strong> <br/>

                <p>The graphics, images, editorial content and HTMC on all the Company's Web Sites are the intellectual property of the Company and are protected by copyright and trademark laws and may not be downloaded or otherwise duplicated without the express written permission of the Company. Re-use of any of the foregoing is strictly prohibited and the Company reserves all rights.</p></li>

                <li> <strong> Responsibility</strong> </li>

                <p>The Company may not monitor its Web Sites at all times but reserves the right to do so. The Company takes no responsibility whatsoever for any material input by any user or others and not posted by the Company. All users acknowledge and agree that they are solely responsible for the form, content and accuracy of any resume, advertisement, web page or material contained therein placed by them. The Company is not responsible for the content of any other web sites linked to the Company's Web Sites; links are provided as internet navigation tools only. The Company does not warrant that any resume, advertisement or web page will be viewed by any specific number of users or that it will be viewed by any specific user. The Company is not in anyway to be considered to be an agent with respect to any users use of the Company's Web Sites and the Company will not be responsible in any way for any decision, for whatever reason made, made by any party seeking or posting jobs on the Company's Web Sites. THE COMPANY DOES NOT WARRANT THAT ITS WEB SITES WILL OPERATE ERROR-FREE OR THAT ITS WEB SITES AND ITS SERVER ARE FREE OF VIRUSES OR OTHER HARMFUL MECHANISMS. IF USE OF THE WEB SITES OR THEIR CONTENTS RESULT IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, BY ANY USER, THE COMPANY WILL NOT RESPONSIBLE FOR THOSE COSTS. THE COMPANY'S WEB SITES AND THEIR CONTENTS ARE PROVIDED ON AN "AS IS" BASIS WITHOUT ANY WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING WITHOUGH PREJUDICE TO THE FOREGOING, ANY IN RESPECT OF MERCHANTABILITY, NON-INFERINGEMENT OF THIRD PARTY RIGHTS, FITNESS FOR PARTICULAR PURPOSE, OR ABOUT THE ACCURACY, RELIABILITY COMPLETENESS OR TIMELINESS OF THE CONTENTS, SERVICES, SOFTWARE, TEXT, GRAPHICS AND LINKS OF THE COMPANY'S WEB SITES.</p>

                <li> <strong> Own Risk</strong> <br/>
                <p>All USERS USE THE COMPANY'S WEB SITES AND ANY OTHER WEB SITES ACCESSED THROUGH IT, AT ENTIRELY THEIR OWN RISK. ALL users are responsible for their own communications and are responsible for the consequences of their posting. The Company does not represent or guarantee the truthfulness, accuracy or reliability of any of the communications posted by other users or endorse any opinions expressed by users. Any reliance by users on material posted by other users will be at their own risk. The Company reserves the right to expel any user and prevent their further access to the Company's Web Sites, at any time for breaching this Agreement or violating the law and also reserves the right to remove any material which is abusive, illegal or disruptive.</p>
                </li>
	
                <li> <strong>Links to Other Sites</strong> <br/>
                
                <p>The Company's Web Sites may contain links to third party Web Sites. These are provided solely as a convenience to you and not in any way as an endorsement by the Company of the contents on such third-party Web Sites. If any user accesses any linked third party Web Sites, they do so entirely at their own risk. The Company is not responsible for the content of any third party Web Sites linked to its Web Sites and does not make any representations or warranties regarding the contents or accuracy of materials on such third-party Web Sites. Further any material or details posted in the Company Web Sites by any user may be viewed by users of other web sites linked to the Company's Web Sites and the Company will not be responsible for any improper use by any user or third party from linked third party web sites of any data or materials posted on the Company's Web Sites.</p>
                </li>

                <li> <strong>Indemnity</strong> <br />

                <p>All users agree to indemnify, and hold harmless the Company, its officers, directors, employees and agents from and against any claims, actions, demands, losses or damages arising from or resulting from their use of the Company's Web Sites or their breach of the terms of this Agreement. The Company shall provide prompt notice of any such claim, suit or proceeding to the relevant user.</p>
                </li>

                <li> <strong> Disclaimer</strong> <br/>

                <p>THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS SHALL NOT BE LIABLE IN ANY EVENT FOR ANY LOSSES OR DAMAGES SUFFERED BY ANY USER WHATSOEVER ARISING OR RESULTING FROM THEIR USE OR INABILITY TO USE THE COMPANY'S WEB SITES AND ITS CONTENTS.</p>

                </li>

                <li><strong>Limitation of Liability </strong><br/>

                <p>ithout prejudice to the above, the aggregate liability by the Company to any user for all claims arising from their use of the Company's services and the Company's Web Sites shall be limited to the amount of the existing package fee subscribed to and paid for by the user (if any) and operative at that time.</p>
                </li>

                <li><strong>Governing Law and Jurisdiction </strong><br/>

                <p>This Agreement and any dispute or matter arising from or incidental to your use of the company's Web Sites shall be governed by the laws of the Indonesia Special Administrative Region and both you and the Company submit to the Jurisdiction of the courts of the Indonesia Special Administrative Region.
                </p>
                </li>
            </ol>
            </div>
        </div>

    </Layout>
)

export default TermsPage